/* Login.css */

@font-face {
  font-family: 'Poppins'; /* Name your custom font family */
  src: url('../../public/Poppins-Regular.ttf') format('ttf');
}

.glassmorphism-container1 {
  width: 470px;
  height: 530px; /* Adjust the width as needed */
  background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.2)); /* Diagonal linear gradient */
  border-radius: 32px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhanced box shadow */
  padding: 20px;
  position: absolute;
  top: 50%;
  right: 5%;
}

.glassmorphism-container1:hover {
  transform: scale(1.03);
  transition: ease-in;
  transition-duration: 0.3s;
}

.orb:hover {
  transform: scale(1.03);
  transition: ease-in-out;
  transition-duration: 0.3s;
}

.glassmorphism-container1 form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.glassmorphism-container1 input,
.glassmorphism-container1 button {
  width: 400px;
  margin-top: 10px;
}

.login-button:hover {
  background-color: #f6b766;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.screen {
  height: 100vh;
  position: relative;
}

label {
  color: white;
}

.Logintext {
  color: white;
  font-size: 35px;
}

.loginbutton {
  background-color: (to right, #ff0000, #0000ff);
  border-radius: 50px;
}

.signuptext {
  color: #007AFF;
}

body {
  font-family: 'Poppins';
  overflow: hidden;
}

.tags {
  font-size: 21px;
}

/* Dark mode scrollbar with sleek edges */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #333; /* Dark background color for the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #555; /* Dark color for the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #474747; /* Dark color for the scrollbar thumb on hover */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .glassmorphism-container1 {
    width: 90%;
    height: auto; /* Adjust height as needed */
    padding: 20px;
    right: 0;
  }

  .glassmorphism-container1 form {
    width: 100%;
  }

  .glassmorphism-container1 input,
  .glassmorphism-container1 button {
    width: 100%;
  }

  .Logintext {
    font-size: 28px;
  }

  .tags {
    font-size: 18px;
  }

  .loginbutton {
    font-size: 16px;
  }

  .signuptext {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .glassmorphism-container1 {
    width: 95%;
    height: auto;
    padding: 15px;
    right: 0;
  }

  .glassmorphism-container1 form {
    width: 100%;
  }

  .glassmorphism-container1 input,
  .glassmorphism-container1 button {
    width: 100%;
  }

  .Logintext {
    font-size: 24px;
  }

  .tags {
    font-size: 16px;
  }

  .loginbutton {
    font-size: 14px;
  }

  .signuptext {
    font-size: 16px;
  }
}

.orb, .orb1 {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .orb, .orb1 {
    display: none; /* Hide orbs on smaller screens for better layout */
  }
}
