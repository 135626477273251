.bg{
    background-image: url('../../public/bg.png');
    height: 200vh;
}
.glassbox {
    width: 350px;
    height: 200px; /* Adjust the width as needed */
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.2)); /* Diagonal linear gradient */
    border-radius: 32px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhanced box shadow */
    padding: 20px;
    animation-name: slideIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
    text-align:"center";
  }

  .glassbox:hover{
    transform: scale(1.03);
    transition: ease-in;
    transition-duration: 0.3s;
    box-shadow: 0 8px 32px rgba(35, 45, 187, 0.452);
  }
  .description-card {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  }
  
  .glassbox:not(:hover) .description-card {
    opacity: 0;
  }